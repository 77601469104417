export default {
  en: {
    common: {
      firstName: 'First name',
      lastName: 'Last name',
      fullName: 'Full name',
      email: 'Email address',
      cancel: 'Cancel',
      wait: 'Please wait'
    },
    header: {
      title: 'Hello There!'
    },
    goalMeter: {
      raised: 'Raised',
      outOf: 'Out of',
      donors: 'Donors'
    },
    frequency: {
      weekly: 'weekly',
      monthly: 'monthly',
      yearly: 'yearly'
    },
    designation: 'Designation',
    donationAmount: {
      title: {
        donate: 'Donation amount',
        support: 'Support amount',
        contribute: 'Contribution amount'
      },
      other: 'Other',
      done: 'Done',
      errorMessage: 'Please select an amount'
    },
    donationFrequency: {
      title: {
        donate: 'Donation frequency',
        support: 'Support frequency',
        contribute: 'Contribution frequency'
      },
      oneTime: 'One-time',
      weekly: 'Weekly',
      monthly: 'Monthly',
      yearly: 'Annually',
      endDate: 'Set an end date',
      byCharges: 'By charges',
      byDate: 'By date',
      errorMessage: 'Please select how often'
    },
    additionalOptions: {
      title: 'Additional options',
      comment: 'Leave us a comment',
      commentPlaceholder: 'Comment',
      dedicate: 'Dedicate this donation',
      dedicateeFullName: 'Dedicatee full name',
      dedicateeEmail: 'Dedicatee email',
      message: 'Message to dedicatee',
      anonymous: 'Donate anonymously',
      companyName: 'Company name',
      behalfCompany: 'Donate on behalf of a company',
      email: 'Email'
    },
    customQuestion: {
      placeholder: 'Choose'
    },
    payment: {
      title: {
        card: 'Payment details',
        plaid: 'Personal information'
      },
      companyName: 'Company name',
      behalfCompany: 'Donate on behalf a company',
      email: 'Email',
      address: 'Address',
      address1: 'Address line 1',
      address2: 'Address line 2',
      city: 'City',
      state: 'State',
      zip: 'Zip code',
      phoneNumber: 'Phone number',
      cardNumber: 'Card number',
      isRequired: 'is required',
      total: {
        donate: 'Total donation',
        support: 'Total support',
        contribute: 'Total contribution'
      },
      coverFees: 'Cover donation fees',
      card: 'Card',
      bank: 'Bank transfer',
      creditCard: 'Credit card',
      bankTransfer: 'Bank transfer',
      tryAgain: 'Try again',
      back: 'Back',
      donate: {
        donate: 'Donate',
        support: 'Donate', // todo
        contribute: 'Contribute'
      },
      plaidTitle: 'Connect your bank account',
      plaidHelp: 'Next, you will be redirected to Plaid, a secure and encrypted bank transfer service to connect your bank account.',
      plaidConnect: 'Connect to your bank',
      week: 'week',
      month: 'month',
      year: 'year',
      weekPayment: 'Weekly payment',
      monthPayment: 'Monthly payment',
      yearPayment: 'Yearly payment'
    },
    confirmation: {
      thanks: 'Thank you',
      share: 'Spread the word!',
      shareDescription: 'Share your donation with your network. 1 out of 4 people will also donate!'
    },
    donorWall: {
      cta: {
        donate: 'Donate',
        support: 'Support',
        contribute: 'Contribute'
      }
    },
    fundraiser: {
      signupConfirmation: "Your fundraiser has been submitted successfully for review. You'll receive an email with your custom link once it's approved.",
      cta: 'Create a fundraiser',
      goal: 'Amount goal',
      apply: 'Apply',
      backDonationForm: 'Back to donation form',
      message: 'Your message...'
    }
  },
  es: {
    common: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      fullName: 'Nombre completo',
      email: 'Email address',
      cancel: 'Cancel',
      wait: 'Por favor, espera'
    },
    header: {
      title: '¡Hola!'
    },
    goalMeter: {
      raised: 'Recaudado',
      outOf: 'De',
      donors: 'donantes'
    },
    frequency: {
      weekly: 'weekly',
      monthly: 'monthly',
      yearly: 'yearly'
    },
    designation: 'Designación',
    donationAmount: {
      title: {
        donate: 'Cantidad de la donación',
        support: 'Support amount',
        contribute: 'Contribution amount'
      },
      other: 'Otro',
      done: 'Hecho',
      errorMessage: 'Please select an amount'
    },
    donationFrequency: {
      title: {
        donate: 'Frecuencia de donación',
        support: 'Support frequency',
        contribute: 'Contribution frequency'
      },
      oneTime: 'Una sola vez',
      weekly: 'Semanalmente',
      monthly: 'Por mes',
      yearly: 'Anualmente',
      endDate: 'Set an end date',
      byCharges: 'By charges',
      byDate: 'By date',
      errorMessage: 'Please select how often'
    },
    additionalOptions: {
      title: 'Opciones adicionales',
      comment: 'Déjanos un comentario',
      commentPlaceholder: 'Comentario',
      dedicate: 'Dedica esta donación',
      dedicateeEmail: 'Email del destinatario',
      anonymous: 'Dona de forma anónima',
      companyName: 'Nombre de la empresa',
      behalfCompany: 'Donar en nombre de una empresa',
      email: 'Email',
      message: 'Mensaje'
    },
    customQuestion: {
      placeholder: 'Selecciona'
    },
    payment: {
      title: {
        card: 'Datos de pago',
        plaid: 'Datos personales'
      },
      companyName: 'Nombre de la empresa',
      behalfCompany: 'Donar en nombre de una empresa',
      email: 'Email',
      address: 'Dirección',
      address1: 'Dirección 1',
      address2: 'Dirección 2',
      city: 'Ciudad',
      state: 'Estado',
      zip: 'Código postal',
      phoneNumber: 'Número de teléfono',
      cardNumber: 'Número de tarjeta',
      total: {
        donate: 'Donación total',
        support: 'Total support', // todo
        contribute: 'Total contribution' // todo
      },
      isRequired: 'es requerido',
      coverFees: 'Cubre los gastos de tramitación añadiendo',
      card: 'Card',
      bank: 'Bank transfer',
      creditCard: 'Credit card',
      bankTransfer: 'Bank transfer',
      tryAgain: 'Vuelve a intentarlo',
      back: 'Volver',
      donate: {
        donate: 'Donar',
        support: 'Donate', // todo
        contribute: 'Contribute' // todo
      },
      plaidTitle: 'Connect your bank account',
      plaidHelp: 'Next, you will be redirected to Plaid, a secure and encrypted bank transfer service to connect your bank account.',
      plaidConnect: 'Connect to your bank',
      week: 'week',
      month: 'month',
      year: 'year',
      weekPayment: 'Weekly payment',
      monthPayment: 'Monthly payment',
      yearPayment: 'Yearly payment'
    },
    confirmation: {
      thanks: 'Gracias',
      share: 'Spread the word!',
      shareDescription: 'Share your donation with your network. 1 out of 4 people will also donate!'
    },
    donorWall: {
      cta: {
        donate: 'Donate',
        support: 'Support',
        contribute: 'Contribute'
      }
    },
    fundraiser: {
      signupConfirmation: "Your fundraiser has been submitted successfully for review. You'll receive an email with your custom link once it's approved.",
      cta: 'Create a fundraiser',
      goal: 'Amount goal',
      apply: 'Apply',
      backDonationForm: 'Back to donation form',
      message: 'Your message...'
    }
  },
  fr: {
    common: {
      firstName: 'Prénom',
      lastName: 'Nom',
      fullName: 'Nom complet',
      email: 'Email address',
      cancel: 'Cancel',
      wait: 'Un instant s.v.p.'
    },
    header: {
      title: 'Bonjour!'
    },
    goalMeter: {
      raised: 'Récolté',
      outOf: 'Sur',
      donors: 'Donateurs'
    },
    frequency: {
      weekly: 'weekly',
      monthly: 'monthly',
      yearly: 'yearly'
    },
    designation: 'Désignation',
    donationAmount: {
      title: {
        donate: 'Montant',
        support: 'Support amount',
        contribute: 'Contribution amount'
      },
      other: 'Autre',
      done: 'Confirmer',
      errorMessage: 'Please select an amount'
    },
    donationFrequency: {
      title: {
        donate: 'Fréquence du don',
        support: 'Support frequency',
        contribute: 'Contribution frequency'
      },
      oneTime: 'Don unique', // TODO
      weekly: 'Hebdomadaire',
      monthly: 'Mensuel',
      yearly: 'Annuel',
      endDate: 'Set an end date',
      byCharges: 'By charges',
      byDate: 'By date',
      errorMessage: 'Please select how often' // TODO
    },
    additionalOptions: {
      title: 'Autres options',
      comment: 'Laissez-nous un commentaire',
      commentPlaceholder: 'Commentaire',
      dedicate: 'Dédiez ce don',
      dedicateeEmail: 'Email du destinataire',
      anonymous: 'Don anonyme',
      companyName: "Nom de l'entreprise",
      behalfCompany: "Faire un don au nom d'une entreprise",
      email: 'Email',
      message: 'Message'
    },
    customQuestion: {
      placeholder: 'Choisir'
    },
    payment: {
      title: {
        card: 'Détails du paiement',
        plaid: 'Informations personnelles '
      },
      companyName: "Nom de l'entreprise",
      behalfCompany: "Faire un don au nom d'une entreprise",
      email: 'Email',
      address: 'Adresse',
      address1: 'Adresse 1',
      address2: 'Adresse 2',
      city: 'Ville',
      state: 'État',
      zip: 'Code postal',
      phoneNumber: 'Numéro de téléphone',
      cardNumber: 'Numéro de carte banquaire',
      total: {
        donate: 'Montant total du don',
        support: 'Total support', // todo
        contribute: 'Total contribution' // todo
      },
      isRequired: 'est obligatoire',
      coverFees: 'Couvrir les frais de traitement en ajoutant',
      card: 'Card',
      bank: 'Bank transfer',
      creditCard: 'Credit card',
      bankTransfer: 'Bank transfer',
      tryAgain: 'Réessayez',
      back: 'Retour',
      donate: {
        donate: 'Donner',
        support: 'Donate', // todo
        contribute: 'Contribute' // todo
      },
      plaidTitle: 'Connect your bank account',
      plaidHelp: 'Next, you will be redirected to Plaid, a secure and encrypted bank transfer service to connect your bank account.',
      plaidConnect: 'Connect to your bank',
      week: 'week',
      month: 'month',
      year: 'year',
      weekPayment: 'Weekly payment',
      monthPayment: 'Monthly payment',
      yearPayment: 'Yearly payment'
    },
    confirmation: {
      thanks: 'Merci',
      share: 'Spread the word!',
      shareDescription: 'Share your donation with your network. 1 out of 4 people will also donate!'
    },
    donorWall: {
      cta: {
        donate: 'Donate',
        support: 'Support',
        contribute: 'Contribute'
      }
    },
    fundraiser: {
      signupConfirmation: "Your fundraiser has been submitted successfully for review. You'll receive an email with your custom link once it's approved.",
      cta: 'Create a fundraiser',
      goal: 'Amount goal',
      apply: 'Apply',
      backDonationForm: 'Back to donation form',
      message: 'Your message...'
    }
  },
  de: {
    common: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      fullName: 'Vollständiger Name',
      email: 'Email address',
      cancel: 'Cancel',
      wait: 'Bitte warten'
    },
    header: {
      title: 'Hallo!'
    },
    goalMeter: {
      raised: 'Gesammelt',
      outOf: 'Von',
      donors: 'Spender'
    },
    frequency: {
      weekly: 'weekly',
      monthly: 'monthly',
      yearly: 'yearly'
    },
    designation: 'Designation',
    other: 'Anderer',
    done: 'Bestätigen',
    errorMessage: 'Please select an amount',
    donationFrequency: {
      title: {
        donate: 'Regelmäßig',
        support: 'Support frequency',
        contribute: 'Contribution frequency'
      },
      oneTime: 'Einmalig',
      weekly: 'Wöchentlich',
      monthly: 'Monatlich',
      yearly: 'Jährlich',
      endDate: 'Set an end date',
      byCharges: 'By charges',
      byDate: 'By date',
      errorMessage: 'Please select how often'
    },
    additionalOptions: {
      title: 'Weitere Optionen',
      comment: "Lass' uns eine Nachricht da",
      commentPlaceholder: 'Nachricht',
      dedicate: 'Diese Spende widmen',
      dedicateeEmail: 'Email des Empfängers',
      anonymous: 'Diese Spende anonym tätigen',
      companyName: 'Name des Unternehmens',
      behalfCompany: 'Im Namen eines Unternehmens spenden',
      email: 'Email', // TODO: remove
      message: 'Nachricht'
    },
    customQuestion: {
      placeholder: 'Wählen'
    },
    payment: {
      title: {
        card: 'Zahlungsinformationen',
        plaid: 'Persönliche Informationen'
      },
      companyName: 'Name des Unternehmens',
      behalfCompany: 'Im Namen eines Unternehmens spenden',
      email: 'Email', // TODO: remove
      address: 'Adresse',
      address1: 'Adresse 1',
      address2: 'Adresse 2',
      city: 'Stadt',
      state: 'Bundesland',
      zip: 'Postleitzahl',
      phoneNumber: 'Telefonnummer',
      cardNumber: 'Kartennummer',
      total: {
        donate: 'Spendensumme',
        support: 'Total support', // todo
        contribute: 'Total contribution' // todo
      },
      isRequired: 'ist ein Pflichtfeld',
      coverFees: 'Decke die Bearbeitungsgebühr durch zusätzliche ',
      card: 'Card',
      bank: 'Bank transfer',
      creditCard: 'Credit card',
      bankTransfer: 'Bank transfer',
      tryAgain: 'Nochmals versuchen',
      back: 'Zurück',
      donate: {
        donate: 'Spenden',
        support: 'Donate', // todo
        contribute: 'Contribute' // todo
      },
      plaidTitle: 'Connect your bank account',
      plaidHelp: 'Next, you will be redirected to Plaid, a secure and encrypted bank transfer service to connect your bank account.',
      plaidConnect: 'Connect to your bank',
      week: 'week',
      month: 'month',
      year: 'year',
      weekPayment: 'Weekly payment',
      monthPayment: 'Monthly payment',
      yearPayment: 'Yearly payment'
    },
    confirmation: {
      thanks: 'Danke',
      share: 'Spread the word!',
      shareDescription: 'Share your donation with your network. 1 out of 4 people will also donate!'
    },
    donorWall: {
      cta: {
        donate: 'Donate',
        support: 'Support',
        contribute: 'Contribute'
      }
    },
    fundraiser: {
      signupConfirmation: "Your fundraiser has been submitted successfully for review. You'll receive an email with your custom link once it's approved.",
      cta: 'Create a fundraiser',
      goal: 'Amount goal',
      apply: 'Apply',
      backDonationForm: 'Back to donation form',
      message: 'Your message...'
    }
  }
};
