<script>
import { getCurrencySymbol } from '@/utils';

export default {
  props: {
    amount: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: 'usd'
    },
    frequency: {
      type: String,
      default: 'single'
    },
    showFrequency: {
      // Will show selected frequency if true && isRecurring
      type: Boolean,
      default: false
    },
    showFrequencySuffix: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    getCurrencySymbol
  }),
  computed: {
    // ...mapState('donation', ['frequency']),
    frequencyFormatted() {
      const frequencies = {
        weekly: this.$t('payment.week'),
        monthly: this.$t('payment.month'),
        yearly: this.$t('payment.year')
      };
      return frequencies[this.frequency];
    },
    isRecurring() {
      return this.frequency && this.frequency !== 'single';
    }
  }
};
</script>

<template>
  <span class="font-nunito">
    <span class="font-normal">{{ getCurrencySymbol(currency) }}</span>
    <span class="font-bold">{{ amount | currency }}</span>
    <template v-if="isRecurring">
      <span v-if="showFrequencySuffix"> {{ $t(`frequency.${frequency}`) }}</span>
      <span v-else-if="showFrequency && !showFrequencySuffix" style="font-size: 0.8em">{{ ' ' }}/{{ frequencyFormatted }}</span>
    </template>
  </span>
</template>
