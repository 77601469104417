import store from '@/store';

export default function (eventType) {
  try {
    if (eventType === 'Double.donationCompleted') {
      const event = new CustomEvent('Double.donationCompleted', {
        detail: {
          donation: {
            amount: store.getters['donation/totalDonation']?.toFixed(2) || 0,
            currency: store.state?.donation?.currency?.toUpperCase() || 'USD',
            id: store.state?.donation?.donationId || null
          }
        }
      });

      document.dispatchEvent(event);
    }
  } catch (error) {
    console.error('Error dispatching event:', error);
  }
}
