<template>
  <div class="infinite-loading" style="height: 1px">
    <slot />
    <div ref="observerTarget" class="observer-target"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineEmits } from 'vue';

const emit = defineEmits(['infinite']);
const observerTarget = ref(null);

onMounted(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        emit('infinite'); // Emit the event when the target is in view
      }
    },
    {
      root: null, // Observe within the viewport
      threshold: 0.1 // Trigger when 10% of the target is visible
    }
  );

  if (observerTarget.value) {
    observer.observe(observerTarget.value);
  }

  onBeforeUnmount(() => {
    if (observerTarget.value) {
      observer.unobserve(observerTarget.value);
    }
    observer.disconnect();
  });
});
</script>
