<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import store from '@/store';
import { widgetViews, loadScript } from '@/utils';
import toaster from '@/mixins/toaster';
import view from '@/mixins/view';
import custom from '@/mixins/custom';
import donationDraft from '@/mixins/donation-draft';
import variables from '@/mixins/variables';
import WidgetIframe from '@/components/WidgetIframe.vue';
import LauncherIframe from '@/components/LauncherIframe.vue';
import ToasterIframe from '@/components/ToasterIframe.vue';
import WidgetLauncher from '@/components/WidgetLauncher.vue';
const DonationForm = () => import('@/views/Form.vue'); // For code splitting (which doesn't work yet)
const DonorWall = () => import('@/views/DonorWall.vue');
const FundraiserSignup = () => import('@/views/FundraiserSignup.vue');
const Confirmation = () => import('@/views/Confirmation.vue');
const WidgetToaster = () => import('@/components/WidgetToaster.vue');
const PaymentForm = () => import('@/views/PaymentForm.vue');
import CheckoutLayout from '@/layouts/CheckoutLayout.vue';

import globalStyles from '!!raw-loader!sass-loader!@/assets/css/client-global.scss';

export default {
  components: {
    WidgetIframe,
    LauncherIframe,
    ToasterIframe,
    WidgetLauncher,
    DonationForm,
    DonorWall,
    FundraiserSignup,
    PaymentForm,
    Confirmation,
    WidgetToaster,
    CheckoutLayout
  },

  mixins: [toaster, view, custom, donationDraft, variables],

  data() {
    return {
      widgetViews,
      publicOrgSettings: store.state.publicOrgSettings.settings,
      recentDonation: null,
      widgetWasOpen: false,
      isFormFirstView: true,
      fundraiser: null
    };
  },

  computed: {
    ...mapGetters('publicOrgSettings', ['isTextLauncher']),
    ...mapState(['orgId', 'isWidgetOpen', 'widgetView', 'successfullyDonated', 'campaign']),
    isMobile() {
      return window.innerWidth < 480; // Not reactive
    },
    checkoutPosition() {
      return {
        left: this.publicOrgSettings.widgetPosition === 'left' ? '0px' : 'auto',
        right: this.publicOrgSettings.widgetPosition === 'right' ? '0px' : 'auto'
      };
    },
    elementPosition() {
      const width = this.isMobile ? 16 : 32;
      return {
        left: this.publicOrgSettings.widgetPosition === 'left' ? `${width}px` : 'auto',
        right: this.publicOrgSettings.widgetPosition === 'right' ? `${width}px` : 'auto'
      };
    },
    viewComponent() {
      switch (this.widgetView.name) {
        case widgetViews.DONATION_FORM.name:
          return 'donation-form';
        case widgetViews.CREDIT_CARD_FORM.name:
          return 'payment-form';
        case widgetViews.DONOR_WALL.name:
          return 'donor-wall';
        case widgetViews.FUNDRAISER_SIGNUP.name:
          return 'fundraiser-signup';
        case widgetViews.CONFIRMATION_DONATION.name:
        case widgetViews.CONFIRMATION_FUNDRAISER.name:
          return 'Confirmation';
        default:
          return 'donation-form';
      }
    }
  },

  watch: {
    successfullyDonated(value) {
      if (value) {
        clearInterval(this.timerInterval); // stop nudging if user has donated
        localStorage.setItem('Double.recentDonationTimeStamp', Date.now()); // save donation timestamp
      }
    },

    isWidgetOpen(value) {
      // Load recaptcha only for affected org...
      const gameChangerId = '767e4400-eee5-11eb-b529-e11231bcf357';
      // const humanAidId = 'ab817660-631a-11eb-9938-8b8726037797';
      // const dafShelSruly = '22692e00-95f4-11ec-b301-2d1fe152986b';
      const orgIds = [gameChangerId];
      if (value && orgIds.includes(this.orgId)) {
        // TODO: env variable
        loadScript('https://www.google.com/recaptcha/api.js?render=6LeIrKcgAAAAADxKeoOl9X3TWYt3mhOHv_mw1bVk', {
          target: document.head,
          isAsync: true
        });
      }
    }
  },

  methods: {
    ...mapMutations(['SET_WIDGET_VIEW', 'SET_SUCCESSFULLY_DONATED']),
    ...mapMutations('campaign', ['RESET_CAMPAIGN']),

    loadGlobalCss() {
      const styleElement = document.createElement('style');
      styleElement.textContent = globalStyles;
      document.head.appendChild(styleElement);
    }
  },

  async created() {
    this.loadGlobalCss();
    this.handleDoubleQueryParams();
  },

  mounted() {
    this.handleFreshLoad();
    this.handleCustomOrgAdjustments();
  }
};
</script>

<template>
  <div class="double-app">
    <!-- ====== DONATION WIDGET ====== -->
    <transition name="widget">
      <div
        v-if="isWidgetOpen"
        :style="checkoutPosition"
        :class="[{ 'widget-open': isWidgetOpen }, { 'text-launcher': isTextLauncher }, publicOrgSettings.widgetPosition]"
        class="widget-frame"
      >
        <widget-iframe>
          <checkout-layout :hide-header="viewComponent === 'Confirmation'">
            <component :is="viewComponent" />
          </checkout-layout>
        </widget-iframe>
      </div>
    </transition>

    <!-- ====== WIDGET LAUNCHER ====== -->

    <div
      v-if="!isWidgetOpen"
      :style="elementPosition"
      :class="[{ 'widget-open': isWidgetOpen }, { 'text-launcher': isTextLauncher }, { 'puff-out': isFreshLoad && publicOrgSettings.displayOverlayButton }]"
      class="launcher-frame"
    >
      <launcher-iframe v-if="publicOrgSettings.displayOverlayButton">
        <widget-launcher />
      </launcher-iframe>
    </div>

    <!-- ====== TOASTER NUDGE ====== -->
    <transition name="toaster">
      <div
        v-if="showToaster"
        :style="elementPosition"
        :class="[{ 'overlay-button': settings.displayOverlayButton }, { 'text-launcher': isTextLauncher }]"
        class="toaster-frame"
      >
        <toaster-iframe>
          <widget-toaster :type="toasterType" :recent-donation="recentDonation" :cta-message="ctaMessage" :nudge-to-show-index="nudgeToShowIndex" />
        </toaster-iframe>
      </div>
    </transition>

    <!-- ====== OVERLAY ====== -->
    <transition name="overlay">
      <div v-if="isWidgetOpen" class="full-overlay"></div>
    </transition>
  </div>
</template>
