<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { widgetViews, getCurrencySymbol } from '@/utils';
import FormLayout from '@/layouts/FormLayout.vue';

export default {
  name: 'DonorWall',
  components: {
    FormLayout
  },

  data() {
    return {
      widgetViews,
      getCurrencySymbol,
      isLoading: false,
      paddingBottom: 160
    };
  },

  computed: {
    ...mapState('donorWall', ['donations', 'totalDonations']),
    ...mapState('campaign', {
      campaign: (state) => state
    }),
    ...mapGetters('campaign', ['hasActiveGoalMeter']),
    isEverythingFetched() {
      return this.donations?.length >= this.totalDonations;
    }
  },

  methods: {
    ...mapMutations(['SET_WIDGET_VIEW']),
    ...mapActions('donorWall', ['fetchDonations']),
    async fetchRecentDonations(more = false) {
      if (this.isLoading || (more && this.isEverythingFetched)) {
        return;
      }
      this.isLoading = true;
      try {
        // const currentScrollTop = this.$refs.donationsContainer.scrollTop;
        await this.fetchDonations(this.campaign.id);
        // this.$refs.donationsContainer.scrollTop = currentScrollTop;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    donorName({ donor }) {
      if (donor) {
        return `${donor.firstName} ${donor.lastName}`;
      }
      return 'Anonymous';
    },
    donationAmount(donation) {
      const { frequency, totalAmount, currency } = donation;
      const amountFormatted = this.getCurrencySymbol(currency) + totalAmount.toLocaleString('en'); // TODO
      if (frequency === 'single') {
        return amountFormatted;
      }
      return `$${totalAmount.toLocaleString('en')} /${frequency}`;
    }
  },
  created() {
    this.fetchRecentDonations();
  },
  mounted() {
    this.paddingBottom = this.$refs.footerSticky.$el.offsetHeight;
  }
};
</script>

<template>
  <form-layout show-header :show-logo="false" title="Donor wall" :style="`padding-bottom: ${paddingBottom}px`">
    <div class="donor-wall slide-up">
      <div ref="donationsContainer" class="donations-container relative mb-12">
        <div v-for="donation in donations" :key="donation.id" class="donation-card flex items-center justify-between p-4 bg-white rounded-lg">
          <span class="donor">
            <app-icon type="svg" name="person" class="person-icon" />
            <span class="name">{{ donorName(donation) }}</span>
          </span>

          <div class="flex">
            <app-price :amount="donation.totalAmount" :currency="donation.currency" :frequency="donation.frequency" />
            <frequency-hint :frequency="donation.frequency" />
          </div>
        </div>
        <!-- <button v-if="!isEverythingFetched" @click="fetchRecentDonations(true)" :disabled="isLoading" class="view-more-button mx-auto pb-3">Load more</button> -->
      </div>
      <infinite-loading @infinite="fetchRecentDonations(true)" />
      <footer-sticky @donate-clicked="SET_WIDGET_VIEW(widgetViews.DONATION_FORM)" ref="footerSticky" />
    </div>
  </form-layout>
</template>
