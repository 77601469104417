<script>
export default {
  name: 'StripeElement',
  data() {
    return {
      ready: false,
      error: null,
      touched: false,
      focused: false,
      element: null
    };
  },
  props: {
    elementType: {
      type: String,
      validator(value) {
        return ['cardNumber', 'cardCvc', 'cardExpiry'].includes(value);
      }
    },
    elementsObject: {
      type: Object
    },
    makeDirty: Boolean
  },
  computed: {
    labels() {
      return {
        cardNumber: this.$t('payment.cardNumber'),
        cardCvc: 'CVC',
        cardExpiry: 'EXP' // Currently not used
      };
    },
    label() {
      return this.labels[this.elementType];
    }
  },
  watch: {
    makeDirty(value) {
      if (value) {
        this.setToDirty();
      }
    }
  },
  methods: {
    createTheElement(elements) {
      if (!elements) {
        return;
      }
      if (this.element) {
        this.ready = false;
        this.$emit('ready', this.ready);
        this.element.destroy();
      }

      const elementStyles = {
        base: {
          fontFamily: '"Nunito", Arial, sans-serif',
          fontWeight: 400,
          lineHeight: '44px',
          color: '#091b27',
          // border: '1px solid red',

          fontSize: '16px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            fontSize: '16px',
            color: '#b1b1bf'
          }
        },
        invalid: {
          color: '#091b27'
        }
      };

      const elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid'
      };
      const elementOptions = {
        style: elementStyles,
        classes: elementClasses,
        placeholder: ''
      };

      if (this.elementType === 'cardNumber') {
        elementOptions.showIcon = true;
      }
      const element = (this.element = elements.create(this.elementType, elementOptions));
      element.on('ready', () => {
        this.ready = true;
        this.$emit('ready', this.ready);
      });
      element.on('change', (event) => {
        if (event.error) {
          this.error = event.error.message;
          this.$emit('state-changed', 'error');
        } else if (event.complete) {
          this.error = null;
          this.$emit('state-changed', 'complete');
        } else if (event.empty) {
          this.error = null;
          this.$emit('state-changed', 'empty');
        }
      });
      element.on('blur', () => {
        if (this.focused) {
          this.setToDirty();
        }
      });
      element.on('focus', () => {
        this.focused = true;
      });
      element.mount(`#${this.elementType}`);
    },
    stripeObjectChanged(val) {
      if (val) {
        this.createTheElement();
      }
    },
    setToDirty() {
      this.touched = true;
      this.$refs.theInput.classList.add('touched');
    }
  },
  async mounted() {
    await this.$nextTick();
    this.$watch('elementsObject', this.createTheElement, { immediate: true });
  }
};
</script>

<template>
  <div class="cc-element" :class="{ 'element-loading': !ready }">
    <div ref="theInput" :id="elementType" class="cc-element-input empty" :style="!ready ? 'visibility: hidden' : null"></div>
    <content-loader v-if="!ready" class="absolute" />
    <template v-else>
      <label for="example-card-number" class="label" :class="elementType" @click="element && element.focus()">{{ label }}</label>
      <ul class="cc-element-input-errors">
        <li class="cc-element-input-error">{{ error }}</li>
        <li class="cc-element-input-error-required" v-if="touched">{{ label }} {{ $t('payment.isRequired') }}.</li>
      </ul>
    </template>
  </div>
</template>
