import { get } from 'lodash';
import request from '../../services/request';

export const state = {
  donations: [],
  totalDonations: 0
};

export const mutations = {
  SET_DONATIONS(state, newValue) {
    state.donations = newValue;
  },
  SET_TOTAL_DONATIONS(state, newValue) {
    state.totalDonations = newValue;
  }
};

export const actions = {
  async fetchDonations({ commit, state, rootState }, campaignId) {
    const params = { offset: get(state.donations, 'length') };

    try {
      const { data, error } = await request.get(
        `/public/recent-donations/${rootState.orgId}/${campaignId}?params=${encodeURIComponent(JSON.stringify(params))}`
      );
      if (error) {
        throw new Error(data);
      }
      const { rows: donations, count } = data;
      const recentDonations = [...(state.donations || []), ...donations];
      commit('SET_DONATIONS', recentDonations);
      commit('SET_TOTAL_DONATIONS', count);
      return recentDonations;
    } catch (err) {
      console.error(err);
    }
  },
  async fetchMostRecentDonation({ rootState }, { minutes }) {
    const minutesAgo = Date.now() - 1000 * (minutes * 60);
    const afterDate = new Date(minutesAgo).toISOString();

    try {
      const { data, error } = await request.get(`/public/last-donation/?orgId=${rootState.orgId}&afterDate=${afterDate}`);
      if (error) {
        throw new Error(data);
      }
      return data[0];
    } catch (e) {
      console.log(e);
    }
  }
};
