<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import request from '@/services/request';

import Iframe from '@/widgets/form/Iframe.vue';
import CheckoutLayout from '@/layouts/CheckoutLayout.vue';
import AppButton from '@/common/AppButton.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import FormOften from '@/components/FormOften.vue';
import FormMainDetails from '@/components/FormMainDetails.vue';
import { setCampaignInUrl } from '@/utils';

export default {
  name: 'FormApp',

  components: {
    CheckoutLayout,
    Iframe,
    AppButton,
    FormLayout,
    FormOften,
    FormMainDetails
  },

  props: {
    campaignSlug: {
      type: String,
      require: false
    }
  },

  data: () => ({
    campaignSettings: null // Important to set to null, we use this to check if campaign settings are loaded or found at all
  }),

  computed: {
    ...mapState(['orgId']),
    ...mapState('publicOrgSettings', ['settings']),
    settingsComputed() {
      if (this.campaignSettings) {
        return this.campaignSettings;
      }

      return this.settings;
    },
    showForm() {
      if (!this.campaignSlug) {
        return true;
      }

      return !!this.campaignSettings?.id;
    },
    hideFrequencyToggle() {
      return this.settingsComputed?.frequenciesEnabled?.length < 2;
    }
  },

  methods: {
    ...mapActions(['setWidgetOpen']),
    ...mapMutations(['SET_WIDGET_VIEW', 'SET_SUCCESSFULLY_DONATED']),
    ...mapActions('campaign', ['fetchCampaign']),
    setCampaignInUrl
  },

  async mounted() {
    // If no campaign slug is passed in form will use default campaign settings
    if (!this.campaignSlug) {
      return;
    }
    const { data: campaign, error } = await request.get(`/public/campaign/${this.orgId}/${this.campaignSlug}`);
    if (error) {
      console.error('Error fetching campaign', error);
      return;
    }

    this.campaignSettings = campaign?.checkoutSettings;
  }
};
</script>

<template>
  <div class="double--form-widget-frame">
    <Iframe v-if="showForm">
      <checkout-layout hide-header static-form :form-campaign-settings="campaignSettings">
        <div class="static-form form-container">
          <form-layout static-form>
            <div class="app-form">
              <div class="form-container">
                <div class="form-content">
                  <form-often v-if="!hideFrequencyToggle" static-form :form-campaign-settings="campaignSettings" />
                  <form-main-details :active="true" static-form :form-campaign-settings="campaignSettings" />
                  <app-button
                    @click="
                      setCampaignInUrl(campaignSlug || settings.defaultCampaignSlug);
                      setWidgetOpen(true);
                    "
                    class="w-full primary"
                  >
                    <h3>{{ settings.ctaTerm || 'Donate' }}</h3>
                  </app-button>
                </div>
              </div>
            </div>
          </form-layout>
        </div>
      </checkout-layout>
    </Iframe>
  </div>
</template>
