import { mapState, mapMutations, mapActions } from 'vuex';

const BAER_MIRYAM_ORGID = '9b8b2390-70fc-11ed-8adb-a1f40ae636e4';
const SHLUCHIM_KIDS = '4cecff10-c1e0-11ef-bb8c-d1a67096d7fd';

export default {
  computed: {
    ...mapState(['orgId'])
  },
  methods: {
    ...mapActions(['setWidgetOpen']),
    ...mapMutations('donation', ['SET_FREQUENCY']),
    handleCustomOrgAdjustments() {
      if (this.orgId === BAER_MIRYAM_ORGID) {
        setTimeout(() => {
          const donateButton = document.querySelector('[aria-label="Donate Now"]');
          if (!donateButton) {
            return; // button not on all pages
          }
          donateButton.addEventListener('click', () => {
            this.setWidgetOpen(true);
            this.SET_FREQUENCY('monthly');
          });
        }, 100);
      }

      if (this.orgId === SHLUCHIM_KIDS) {
        setTimeout(() => {
          const donateButtons = document.querySelectorAll('div.wixui-button');
          console.log({ donateButtons });
          if (!donateButtons.length) {
            return; // button not on all pages
          }
          donateButtons.forEach((button) => {
            button.addEventListener('click', (event) => {
              event.preventDefault();
              const campaign = window.location.href.includes('/1') ? 'anash-landing-page' : 'landing-page';
              window.Double.openCheckout({ campaign });
            });
          }, 100);
        });
      }
    }
  }
};
