import { mapMutations, mapActions, mapState } from 'vuex';

import { widgetViews, setCampaignInUrl, unparam } from '@/utils';

export default {
  computed: {
    ...mapState('publicOrgSettings', {
      globalSettings: (state) => state.settings
    }),
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapState('campaign', {
      campaign: (state) => state
    })
  },

  methods: {
    ...mapActions(['setWidgetOpen']),
    ...mapActions('fundraiser', ['fetchFundraiser']),
    ...mapMutations(['SET_WIDGET_VIEW']),
    ...mapMutations('donation', [
      'SET_AMOUNT',
      'SET_FREQUENCY',
      'SET_HAS_END_DATE',
      'SET_RECURRING_TYPE',
      'SET_NUM_CHARGES',
      'SET_UTM_PARAMETERS',
      'SET_SOURCE_URL'
    ]),

    async handleDoubleQueryParams() {
      // Currently we handle 4 param queries: double=true, double=donate, double=fundraiser, fundraiser=slug
      const queryParams = unparam(location.search);
      const { double, donsplus, fundraiser, 'double-draft': doubleDraft, campaign } = queryParams;

      if (!double && !fundraiser && !donsplus && !doubleDraft && !campaign) {
        return;
      }

      // ?donsplus=true is supported for legacy reasons
      const autoOpen = (double || donsplus) === 'true' || double === 'donate' || double === 'fundraise' || fundraiser || doubleDraft || campaign;
      if (autoOpen) {
        await this.$nextTick(); // In order to trigger the `widget` transition
        this.setWidgetOpen(true);
      }

      const fundraiserSlug = fundraiser || sessionStorage.getItem('double.fundraiserSlug');
      if (fundraiserSlug) {
        this.handleFundraiserLink(fundraiserSlug);
      }

      const fundraiserSignup = double === 'fundraise';
      if (fundraiserSignup && this.publicOrgSettings.p2pEnabled) {
        this.SET_WIDGET_VIEW(widgetViews.FUNDRAISER_SIGNUP);
      }
    },
    handleFundraiserLink(fundraiserSlug) {
      // if (!this.publicOrgSettings.p2pEnabled) {
      // return console.warn('Double: P2P is not enabled for this organization');
      // }

      this.fetchFundraiser({ fundraiserSlug });
      sessionStorage.setItem('Double.fundraiserSlug', fundraiserSlug);
    },

    getUTMParameters() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });

      return {
        utm_source: params.utm_source,
        utm_medium: params.utm_medium,
        utm_campaign: params.utm_campaign,
        utm_term: params.utm_term,
        utm_content: params.utm_content
      };
    }
  },
  created() {
    window.Double = {
      openCheckout: async (params) => {
        if (params?.campaign) {
          const campaignSlug = params?.campaign || this.globalSettings.defaultSlug;
          setCampaignInUrl(campaignSlug, true);
        }

        if (params?.tab) {
          this.SET_WIDGET_VIEW(widgetViews[params.tab]);
        }

        // await in order to have the campaign loaded
        await this.setWidgetOpen(true);

        if (params?.amount) {
          this.SET_AMOUNT(params.amount);
        }

        if (params?.frequency) {
          if (!this.campaign.settings.frequenciesEnabled.includes(params.frequency)) {
            return;
          }
          this.SET_FREQUENCY(params.frequency);
        }

        if (params?.charges) {
          const charges = Number(params.charges);
          if (Number(charges || 0) < 2 || this.donation.frequency === 'single') {
            return;
          }

          this.SET_HAS_END_DATE(true);
          this.SET_RECURRING_TYPE('byCharges');
          this.SET_NUM_CHARGES(charges);
        }
      },
      closeCheckout: () => this.setWidgetOpen(false)
    };

    window.Double.openWidget = window.Double.openCheckout; // Legacy, don't remove!
    window.Double.closeWidget = window.Double.closeCheckout; // Legacy, don't remove!
    window.donsPlus = window.donsplus = window.Double; // Legacy, don't remove!

    const utms = this.getUTMParameters();
    this.SET_UTM_PARAMETERS(utms);
    this.SET_SOURCE_URL(window.location.href);
  }
};
