<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { unparam, handleNumberInput, MIN_AMOUNT, MAX_AMOUNT } from '@/utils';
import AppButton from '@/common/AppButton.vue';
import FormDesignation from '@/components/FormDesignation.vue';
import CustomQuestions from '@/components/CustomQuestions.vue';

export default {
  name: 'FormAdditionalOptions',
  components: {
    AppButton,
    FormDesignation,
    CustomQuestions
  },
  props: {
    active: Boolean,
    showError: Boolean,
    fieldsDirty: Boolean,
    staticForm: {
      type: Boolean,
      default: false
    },
    formCampaignSettings: {
      type: Object,
      default: () => null
    }
  },

  data() {
    return {
      isCustomInputOpen: false,
      amountValidationMessage: '',
      handleNumberInput,
      areDedicationFieldsDirty: false
    };
  },

  computed: {
    ...mapState('publicOrgSettings', ['settings']),
    ...mapState('campaign', {
      campaignSettings: (campaign) => campaign.settings
    }),
    settingsComputed() {
      if (this.formCampaignSettings) {
        return this.formCampaignSettings;
      }

      return this.staticForm ? this.settings : this.campaignSettings;
    },
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapGetters('donation', ['currencySymbol']),
    amount: {
      get() {
        return this.donation.amount;
      },
      set(value) {
        this.SET_AMOUNT(value);
      }
    },
    coveringFees: {
      get() {
        return this.donation.payCoverFees;
      },
      set(value) {
        this.SET_PAY_COVER_FEES(value);
      }
    },

    suggestedAmounts() {
      /* To avoid logic in template code:
       Unify suggested amounts (manually selected with
       description & smart suggestions without
    */
      // First render
      if (!this.settingsComputed?.suggestedAmounts) {
        return [];
      }
      const amounts = this.settingsComputed.suggestedAmounts;
      const suggestedAmounts = amounts[this.donation.frequency] || amounts.single;
      const isSmartSuggestions = this.settingsComputed.smartSuggestionsEnabled;
      if (isSmartSuggestions) {
        return suggestedAmounts.map((amount) => ({
          amount,
          description: ''
        }));
      }

      return suggestedAmounts;
    },

    someAmountHasDescription() {
      return this.suggestedAmounts.some((amount) => amount.description.length > 0);
    },

    expandColumns() {
      const longestDescriptionLength = this.suggestedAmounts.reduce((acc, suggestedAmount) => {
        return suggestedAmount.description.length > acc ? suggestedAmount.description.length : acc;
      }, 0);

      if (longestDescriptionLength > 45) {
        return 'expand-1';
      }

      if (longestDescriptionLength > 32) {
        return 'expand-2';
      }
      return null;
    }
  },

  watch: {
    amount(value) {
      this.SET_AMOUNT(value);

      const description = this.suggestedAmounts.find((suggestedAmount) => suggestedAmount.amount === value)?.description || null;
      this.SET_AMOUNT_DESCRIPTION(description);

      if (this.validateAmount()) {
        this.amountValidationMessage = ''; // reset validation message
      }
    },
    active(newValue) {
      if (!newValue) {
        this.isCustomInputOpen = false;
      }
    },

    isCustomInputOpen(newValue) {
      this.$emit('is-custom-amount-open', newValue);
    }
  },

  methods: {
    // **** Business logic
    ...mapMutations('donation', ['SET_AMOUNT', 'SET_AMOUNT_DESCRIPTION', 'SET_PAY_COVER_FEES', 'SET_FREQUENCY']),

    selectAmount(amount) {
      this.amount = amount;
      this.SET_AMOUNT(this.amount);
    },

    validateAmount() {
      // suggested amounts
      if (!this.isCustomAmountSelected(this.amount)) {
        return this.amount !== null;
      }
      if (this.amount < MIN_AMOUNT) {
        this.amountValidationMessage = `Minimum donation amount is ${this.currencySymbol}5`;
        return;
      }
      if (this.amount > MAX_AMOUNT) {
        this.amountValidationMessage = `Maximum donation amount is ${this.currencySymbol}${MAX_AMOUNT}`;
        return;
      }

      return true;
    },

    // **** UI
    selectCustomAmount() {
      const isAmountValid = this.validateAmount(this.amount);
      if (!isAmountValid) {
        return;
      }
      this.isCustomInputOpen = false;
    },

    async openCustomAmountInput() {
      if (this.isCustomInputOpen) {
        return;
      }

      this.isCustomInputOpen = true;
      await this.$nextTick();
      const input = this.$el.querySelector('#custom-amount-input');
      input.focus();
      input.select();
    },

    isCustomAmountSelected(amount) {
      return amount && !this.isSuggestedAmount(amount);
    },

    isSuggestedAmount(amount) {
      const suggestedAmounts = this.suggestedAmounts.map((suggestedAmount) => suggestedAmount.amount);
      if (!suggestedAmounts.includes(amount)) {
        return false;
      }

      return amount === this.amount;
    },

    // **** Helper methods
    hasNDecimals(value, number) {
      return value.indexOf('.') >= 0 && value.length - value.indexOf('.') > number;
    },

    isInValidAmountRange(value) {
      const numOfChars = String(value).length;
      return numOfChars <= 4;
    },
    handleFrequencyParams() {
      const frequencies = {
        'one-time': 'single',
        weekly: 'weekly',
        monthly: 'monthly',
        annually: 'yearly'
      };

      const queryParams = unparam(location.search);
      const frequencyParam = queryParams.frequency;

      if (!frequencyParam) {
        return;
      }

      const frequency = frequencies[frequencyParam.toLowerCase()];
      this.SET_FREQUENCY(frequency);
    }
  },

  created() {
    this.handleFrequencyParams();
  },
  mounted() {
    if (this.settingsComputed.hideOtherButton && this.suggestedAmounts.length === 1) {
      this.selectAmount(this.suggestedAmounts[0].amount);
    }
  }
};
</script>

<template>
  <section ref="form-main-details" :class="{ 'section-has-error': showError && !amount }" class="form-main-details mb-8">
    <div ref="suggestedAmounts" class="suggested-amounts" :class="expandColumns">
      <app-button
        v-for="(suggestedAmount, i) in suggestedAmounts"
        :key="i"
        :selected="!isCustomInputOpen && isSuggestedAmount(suggestedAmount.amount)"
        @click="
          selectAmount(suggestedAmount.amount);
          isCustomInputOpen = false;
        "
      >
        <div class="flex items-center justify-center">
          <h3 class="amount">
            <span class="currency-symbol">{{ currencySymbol }}</span>
            <span>{{ suggestedAmount.amount | number }}</span>
          </h3>
          <frequency-hint :frequency="donation.frequency" />
        </div>
        <p v-if="suggestedAmount.description.length > 0" class="subtext-small-text-semibold text-black-01">
          {{ suggestedAmount.description }}
        </p>
      </app-button>
      <app-button
        v-if="!settingsComputed.hideOtherButton || isCustomAmountSelected(amount)"
        class="custom-amount"
        @click="openCustomAmountInput"
        :selected="isCustomInputOpen || isCustomAmountSelected(amount)"
        :focused="isCustomInputOpen"
      >
        <div class="flex items-center justify-center">
          <h3 class="amount">
            <span class="label" v-if="!isCustomAmountSelected(amount)" @click="isCustomInputOpen = true">{{ $t('donationAmount.other') }}</span>
            <span v-if="isCustomAmountSelected(amount)" class="selected-label">
              <span class="currency-symbol">{{ currencySymbol }}</span>
              <span>{{ amount || 0 | number }}</span>
            </span>
          </h3>
          <frequency-hint v-if="isCustomAmountSelected(amount)" :frequency="donation.frequency" />
        </div>
        <p v-if="isCustomAmountSelected(amount) && someAmountHasDescription" class="subtext-small-text-semibold text-black-01">Other amount</p>
      </app-button>
    </div>
    <transition name="expandx3">
      <div v-if="isCustomInputOpen" class="relative mt-2.5">
        <div class="custom-currency absolute text-black-01">{{ currencySymbol }}</div>
        <formulate-input
          v-model.number="amount"
          @focusout="selectCustomAmount"
          @keyup.enter="selectCustomAmount"
          @keydown="handleNumberInput"
          type="number"
          ref="customAmountInput"
          min="5"
          max="99999"
          id="custom-amount-input"
          class="body-text-regular w-full"
          name="customAmount"
        />
        <frequency-hint class="custom-frequency absolute" :frequency="donation.frequency" />
      </div>
    </transition>
    <div class="mt-1">
      <span v-if="amountValidationMessage" class="custom-error-message">{{ amountValidationMessage }}</span>
      <span v-else-if="showError && !amount" class="custom-error-message">{{ $t('donationAmount.errorMessage') }}</span>
    </div>
    <template v-if="!staticForm">
      <form-designation class="mt-4" />
      <custom-questions v-if="settingsComputed.isCustomQuestionsActive" :fields-dirty="fieldsDirty" class="mt-8" />
    </template>
  </section>
</template>
